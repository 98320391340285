<template>
  <div class="grid min-h-screen sm:grid-cols-2 xl:grid-cols-3">
    <aside class="hidden items-center justify-items-center p-3 sm:grid xl:col-span-2">
      <img src="/img/unprotected-layout1.jpg" alt="." class="size-full rounded-lg border border-gray-200 object-cover  brightness-75 dark:border-gray-700" />
    </aside>

    <main class="relative flex w-full flex-col items-center justify-center p-8">
      <slot></slot>
    </main>
  </div>
</template>

<script setup lang="ts"></script>
